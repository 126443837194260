html,
body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-requestcode {
  max-width: 330px;
  padding: 15px;
}

.form-requestcode .form-floating:focus-within {
  z-index: 2;
}

.form-requestcode input[type="email"] {
  margin-bottom: 10px;
}
