/* Custom page CSS
 * -------------------------------------------------- */
/* Not required for template or sticky footer method. */

main > .container {
	  padding: 60px 15px 0;
  }

.footer{
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgb(235, 195, 64);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
